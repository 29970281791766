import { Link } from "react-router-dom";
import { auth } from "../users/firebase";
import { useUser } from "../state/UserContext";

export const HomePage = () => {
  const user = useUser();

  return (
    <>
      <div className="absolute w-screen flex justify-center">
        <h1 className="text-bold text-center">BIMnerd Network</h1>
      </div>
      <div className="w-full h-screen flex justify-center items-center">
        <div className="p-5 border-solid border-4 text-center rounded-xl">
          {!user && (
            <>
              <Link to="/login">
                <h2 className="font-bold">Login</h2>
              </Link>
              <Link to="/subscribe">
                <h2 className="font-bold">Subscribe</h2>
              </Link>
            </>
          )}
          {user && (
            <>
              <Link to="/account">
                <h2 className="font-bold">My account</h2>
              </Link>
              <button onClick={() => auth.signOut()}>
                <h2 className="font-bold">Logout</h2>
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};
