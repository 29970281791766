import { createBrowserRouter } from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import { LoginPage } from "./pages/LoginPage";
import { SubscribePage } from "./pages/SubscribePage";
import { AccountPage } from "./pages/AccountPage";
import { Layout } from "./Layout";

export const routes = {
  homepage: "/",
  login: "/login",
  subscribe: "/subscribe",
  myAccount: "/account",
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: routes.homepage,
        element: <HomePage />,
      },
      {
        path: routes.login,
        element: <LoginPage />,
      },
      {
        path: routes.subscribe,
        element: <SubscribePage />,
      },
      {
        path: routes.myAccount,
        element: <AccountPage />,
      },
    ],
  },
]);
