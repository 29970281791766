import { useCallback, useEffect, useMemo, useState } from "react";
import { useFirebaseActions } from "../users/firebase-actions";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import MailChecker from "mailchecker";
import { useUser } from "../state/UserContext";
import { routes } from "../routes";
import { MoonLoader } from "react-spinners";

export const LoginPage = () => {
  // check if already logged in
  const navigate = useNavigate();
  const user = useUser();
  useEffect(() => {
    if (user) {
      navigate(routes.myAccount);
    }
  }, [navigate, user]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isPending, setIsPending] = useState(false);

  const { loginUser } = useFirebaseActions();

  const formError = useMemo(() => {
    if (!MailChecker.isValid(email)) {
      return "Enter a valid email address";
    }
    return undefined;
  }, [email]);

  const handleLogin = useCallback(
    async (e: any) => {
      e.preventDefault();

      if (formError) {
        setError(formError);
        return;
      }

      setIsPending(true);
      const result = await loginUser(email, password);
      setIsPending(false);

      if (result?.errorMessage) {
        setError(result.errorMessage);
      }
    },
    [email, formError, loginUser, password]
  );

  return (
    <form
      className="w-full h-screen flex justify-center items-center"
      method="POST"
    >
      <Link to="/" className="absolute left-10 top-10">
        <IoMdArrowRoundBack className="w-10 h-10" />
      </Link>
      <div className="flex flex-col gap-2 relative w-full max-w-md mx-auto">
        <label htmlFor="email" className="text-p">
          Email
        </label>
        <input
          type="email"
          className="p-2 text-p text-black"
          onChange={(e) => setEmail(e.target.value)}
        />

        <label htmlFor="password" className="text-p mt-3">
          Password
        </label>
        <input
          type="password"
          className="p-2 text-p text-black"
          onChange={(e) => setPassword(e.target.value)}
        />

        {!isPending && (
          <button
            type="submit"
            className="hover:text-petrolblauw cursor-pointer text-h3 pt-5"
            onClick={handleLogin}
          >
            Login
          </button>
        )}

        {isPending && <MoonLoader color="white" className="self-center mt-5" />}

        {error && (
          <p className="text-red absolute bottom-0 translate-y-[100%] text-center pt-5">
            {error}
          </p>
        )}
      </div>
    </form>
  );
};
