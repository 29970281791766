import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../state/UserContext";
import { useEffect, useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { auth, firebaseFunctions } from "../users/firebase";
import { MoonLoader } from "react-spinners";

export const AccountPage = () => {
  const navigate = useNavigate();
  const user = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [navigate, user]);

  const joinCommunity = async () => {
    try {
      setIsLoading(true);
      const result = await firebaseFunctions.createPaymentLink();
      window.location.href = result.data.paymentLink;

      setIsLoading(false);
      setError("");
    } catch (error) {
      setError("Failed to join community. Please try again later");
    }
  };

  const leaveCommunity = async () => {
    if (
      window.confirm(
        "Are you sure you want to cancel your subscription? You will have access until the next billing cycle"
      )
    ) {
      try {
        setIsLoading(true);
        await firebaseFunctions.leaveCommunity();
        setIsLoading(false);
      } catch (error) {
        alert("Failed to cancel subscription. Please try again later");
      }
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete your account?")) {
      try {
        setIsLoading(true);
        await firebaseFunctions.deleteUser();
        setIsLoading(false);
      } catch (error) {
        alert("Failed to delete your account. Please try again later");
      }
      auth.signOut();
    }
  };

  if (!user) {
    return null;
  }

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <Link to="/" className="absolute left-10 top-10">
        <IoMdArrowRoundBack className="w-10 h-10" />
      </Link>
      <form method="post"></form>

      <div className="flex flex-col gap-2 relative text-center">
        {<h2>Welcome, {user.firstName} {user.lastName} </h2>}
        {!user.moneybirdSubscriptionId && (
          <button onClick={joinCommunity} style={{ textDecoration: "none" }}>
            <h3 className="text-applegreen text-bold transition-all duration-500 hover:scale-110 ">
              {user.isActiveCommunityMember ? "Resume subscription" : "Start subscription"}
            </h3>
          </button>
        )}
        {user.isActiveCommunityMember && user.moneybirdSubscriptionId && (
          <button onClick={leaveCommunity} style={{ textDecoration: "none" }}>
            <h3 className="text-red text-bold">Cancel subscription</h3>
          </button>
        )}
        {error.length > 0 && <p className="text-red">{error}</p>}
        {!isLoading && (
          <button
            onClick={handleDelete}
            className=" text-red p-2 rounded-md bg-white fixed bottom-0 right-0 m-5"
          >
            Delete my account
          </button>
        )}
        {isLoading && <MoonLoader color="white" className="self-center mt-5" />}
      </div>
    </div>
  );
};
