import { signInWithEmailAndPassword } from "firebase/auth";
import { useCallback } from "react";
import { auth, firebaseFunctions } from "./firebase";
import MailChecker from "mailchecker";
import { RegisterError } from "../../../functions/shared/requests";

export const useFirebaseActions = () => {
  const loginUser = useCallback(async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error: any) {
      if (
        error.code === "auth/invalid-credential" ||
        error.code === "auth/user-not-found"
      ) {
        return { errorMessage: "Invalid email or password" };
      }

      console.log(error);
      return { errorMessage: "Error while logging in. Please try again" };
    }
  }, []);

  const registerUser = useCallback(
    async (
      email: string,
      password: string,
      phoneNumber: number,
      firstName: string,
      lastName: string
    ) => {
      if (!email || !password) {
        console.error("Email and password are required");
        return;
      }

      try {
        if (!MailChecker.isValid(email)) {
          return { errorMessage: "Email is not valid" };
        }

        await firebaseFunctions.registerNewUser({
          email,
          password,
          phoneNumber,
          firstName,
          lastName,
        });

        await signInWithEmailAndPassword(auth, email, password);
      } catch (error: any) {
        if ((error.details?.code as RegisterError) === "email-exists") {
          return { errorMessage: "Email is already registered" };
        }

        return { errorMessage: "Error while registering. Please try again" };
      }
    },
    []
  );

  return { loginUser, registerUser };
};
