import { useCallback, useMemo, useState } from "react";
import { useFirebaseActions } from "../users/firebase-actions";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
import MailChecker from "mailchecker";
import { MoonLoader } from "react-spinners";

export const SubscribePage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [error, setError] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const { registerUser } = useFirebaseActions();

  const formError = useMemo(() => {
    if (!MailChecker.isValid(email)) {
      return "Enter a valid email address";
    }
    if (
      phoneNumber.toString().length < 8 ||
      phoneNumber.toString().indexOf(".") !== -1
    ) {
      return "Enter a valid phone number";
    }
    if (password.length < 8) {
      return "Password must be at least 8 characters long";
    }
    if (firstName.length < 3) {
      return "Please enter your name";
    }
    if (lastName.length < 3) {
      return "Please enter your last name";
    }
    if (password !== passwordRepeat) {
      return "Passwords do not match";
    }
    return undefined;
  }, [email, firstName.length, lastName.length, password, passwordRepeat, phoneNumber]);

  const handleRegister = useCallback(
    async (e: any) => {
      e.preventDefault();

      if (formError) {
        setError(formError);
        return;
      }

      setIsPending(true);
      const result = await registerUser(email, password, phoneNumber, firstName, lastName);
      setIsPending(false);

      if (result?.errorMessage) {
        setError(result.errorMessage);
      }
    },
    [email, formError, firstName, lastName, password, phoneNumber, registerUser]
  );

  return (
    <form
      className="w-full h-screen flex justify-center items-center"
      method="POST"
    >
      <Link to="/" className="absolute left-10 top-10">
        <IoMdArrowRoundBack className="w-10 h-10" />
      </Link>
      <div className="flex flex-col gap-2 relative w-full max-w-md mx-auto">
        <label htmlFor="email" className="text-p">
          Email
        </label>
        <input
          id="email"
          type="email"
          className="p-2 text-p text-black"
          onChange={(e) => setEmail(e.target.value)}
        />

        <label htmlFor="firstName" className="text-p">
          First name
        </label>
        <input
          id="firstName"
          type="string"
          className="p-2 text-p text-black"
          onChange={(e) => setFirstName(e.target.value)}
        />

        <label htmlFor="lastName" className="text-p">
          Last name
        </label>
        <input
          id="lastName"
          type="string"
          className="p-2 text-p text-black"
          onChange={(e) => setLastName(e.target.value)}
        />

        <label htmlFor="password" className="text-p mt-3">
          Password
        </label>
        <input
          id="password"
          type="password"
          className="p-2 text-p text-black"
          onChange={(e) => setPassword(e.target.value)}
        />

        <label htmlFor="passwordAgain" className="text-p mt-3">
          Password again
        </label>
        <input
          id="passwordAgain"
          type="password"
          className="p-2 text-p text-black"
          onChange={(e) => setPasswordRepeat(e.target.value)}
        />

        <label htmlFor="phoneNumber" className="text-p mt-3">
          Phone number
        </label>
        <input
          id="phoneNumber"
          type="number"
          className="p-2 text-p text-black"
          onChange={(e) => setPhoneNumber(parseInt(e.target.value))}
        />

        {!isPending && (
          <button
            type="submit"
            className="hover:text-petrolblauw cursor-pointer text-h3 pt-5"
            onClick={handleRegister}
          >
            Subscribe
          </button>
        )}

        {isPending && <MoonLoader color="white" className="self-center mt-5" />}

        {error && (
          <p className="text-red absolute bottom-0 translate-y-[100%] text-center pt-5">
            {error}
          </p>
        )}
      </div>
    </form>
  );
};
