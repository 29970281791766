import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { UserObject } from "../../functions/shared/requests";
import { routes } from "./routes";
import { UserContext } from "./state/UserContext";
import { auth, firebaseFunctions } from "./users/firebase";
import { useInterval } from "usehooks-ts";

export const Layout = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState<UserObject | null | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  useInterval(async () => {
    if (user) {
      const result = await firebaseFunctions.getCurrentUserObject();
      setUser(result.data);
    }
  }, 2000);

  // handle retrieving user data
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(null);

        setIsLoading(true);
        const response = await firebaseFunctions.getCurrentUserObject();
        setIsLoading(false);

        setUser(response.data);
        navigate(routes.myAccount);
      } else {
        setUser(null);
      }
    });
  }, [navigate]);

  return (
    <UserContext.Provider value={{ user }}>
      {/* wait for firebase to retrieve auth status */}
      {user !== undefined && !isLoading ? (
        <Outlet />
      ) : (
        <div className="fixed inset-0 flex justify-center items-center">
          <MoonLoader color="white" className="w-10 h-10" />
        </div>
      )}
    </UserContext.Provider>
  );
};
